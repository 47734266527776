@import "breakpoint";
@import "settings";
@import "foundation/components/global"; // *always required
@import "foundation/components/grid";

.video .paragraphs-item-full-screen-video.hasavideo {
  //background: #454545;
  //background-image: none;
}

.paragraphs-item-full-screen-video video {
  opacity: 1;
  transition: opacity .5s ease-out;

  @include breakpoint($mobile) {
    display: none;
  }

}

.paragraphs-item-full-screen-video video.hidden {
  opacity: 0;
  transition: opacity .5s ease-out;
}

.paragraphs-item-full-screen-video {
  background-size: cover;
  background-position: center top;
  position: relative;
  overflow: hidden;
  @include breakpoint($mobile) {
    height: 250px;
    //padding-bottom: 56.25%;
  }

  @include breakpoint($desktop) {
    background-attachment: fixed;
    height: 100vh;
  }
}

_::-webkit-:not(:root:root), .paragraphs-item-full-screen-video {
  background-attachment: inherit;
}


.ds-overlay-video {
  text-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}

.scroll-suggestion {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  @include breakpoint($mobile) {
    background: url(../../images/scroll-suggestion-mobile.png) center top;
    height: 48px;
  }
  @include breakpoint($desktop) {
    background: url(../../images/scroll-suggestion.png) center top;
    height: 93px;
  }
}

.ds-overlay-video {
  padding-left: 15px;
  position: relative;
  @include breakpoint($mobile) {
    padding-top: 15%;
  }

  @include breakpoint($desktop) {
    top: 54%;
    transform: perspective(1px) translateY(-50%);
  }
  img {
    width: 45%;
    @include breakpoint($mobile) {
      width: 50%;
    }
  }

  .show-showreel {
    position: relative;
    margin-top: 20px;
    vertical-align: middle;
    display: inline-block;
    font-size: 14px;
    color: white;
    text-transform: uppercase;
    text-decoration: none;
    border-bottom: 2px solid white;
    padding-top: 3px;
    @include breakpoint($desktop) {
      padding-top: 30px;
    }
    &:after {
      content: "";
      position: absolute;
      top: 0;
      margin-left: 10px;
      display: inline-block;
      vertical-align: middle;
      //content: url("showreel-icon.png");
      background-size: contain;
      background-image: url("showreel-icon.png");
      width: 30px;
      height: 30px;

      @include breakpoint($desktop) {
        width: 86px;
        height: 86px;
      }
    }
  }
}

.front .show-showreel:after {
  background-image: url("showreel-icon-home.png");
}

.theme-digital .show-showreel:after {
  background-image: url("showreel-icon.png");
}

.theme-brand .show-showreel:after {
  background-image: url("showreel-icon-brand.png");
}

.theme-shopper .show-showreel:after {
  background-image: url("showreel-icon-shopper.png");
}

.video-bg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100%;
  min-height: 100%;
  height: auto;
  width: auto;

  @include breakpoint($mobile) {
    width: 444px;
  }

}

.paragraphs-item-full-screen-video h1 {
  font-size: 16px;
  margin-top: -20px;
  color: white;
  @include breakpoint($desktop) {
    margin-top: 0;
    font-size: 28px;
  }
  &:after {
    padding-top: 4px;
    margin-bottom: 12px;
    @include breakpoint($desktop) {
      padding: 8px;
      margin-bottom: 30px;
    }
    content: "";
    display: block;
    width: 44px;
    border-bottom: 3px solid white;
  }
}

.ds-overlay-image h1:after {
  display: none !important;
}

.paragraphs-item-full-screen-video .field-name-field-body {
  @include breakpoint($desktop) {
    font-size: 65px;
    line-height: 1.25em;
  }
  color: white;
  font-family: $font-headings;
  text-transform: uppercase;
  max-width: 720px;
  font-weight: 700;
}
